import React from 'react';

const GoogleMap = () => {
    return (
        <>
            <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Uzhaipallar%20Nagar,%20Chennai,%20Tamil%20nadu,%20india%20-%20600072+(Ratoon%20Infotech%20Private%20Limited)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="450" loading="lazy"></iframe>
        </>
    )
}

export default GoogleMap;  